<template>
	<div class="flex flex-col gap-2">
		<label class="text-base leading-6 cursor-pointer" :class="labelClass" v-if="label" :for="id">
			{{ label }}
		</label>
		<input
			class="rounded-xl text-base leading-6 font-normal text-white placeholder:text-gray-700 bg-transparent border border-extended-blue-gray-900 py-6 px-4"
			:id="id"
			:class="inputClass"
			:value="modelValue"
			:placeholder="placeholder"
			:type="type"
			:required="required"
			@input="onChange"
			:disabled="disabled"
			:minlength="minlength"
			:maxlength="maxlength"
			:readonly="readonly"
			:inputmode="inputmode"
		/>
	</div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'

const props = defineProps<{
	inputmode?: string
	inputClass?: string
	minlength?: string
	maxlength?: string
	disabled?: boolean
	type?: string
	placeholder?: string
	labelClass?: string
	label?: string
	required?: boolean
	modelValue?: string | number
	readonly?: boolean
}>()

const id = useId()

const emit = defineEmits<{
	(e: 'update:modelValue', value: string | number): void
}>()

const onChange = (event: Event) => {
	const target = event.target as HTMLInputElement
	emit('update:modelValue', target.value)
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
</style>
